/*eslint-disable*/
import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";

export default function Footer(props) {
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent='space-between'
      px='30px'
      pb='20px'>
      <Text
        color='gray.400'
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}>
        Copyright &copy; {1900 + new Date().getYear()}{" "}
        <Link
          color='blue.400'
          href='http://wedabergast.byethost8.com/index.htm'
          target='_blank'>
            Dinas Pendidikan Kepemudaan dan Olahraga Kabupaten Klungkung
        </Link>
      </Text>
      <Text
        color='gray.400'
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}>
        Powered by{" "}
        <Link
          color='blue.400'
          href='https://www.maiharta.com/'
          target='_blank'>
            Maiharta
        </Link>
      </Text> 
    </Flex>
  );
}
