// import
import React, { Component }  from 'react';
import Dashboard from "views/Dashboard/Dashboard.js";
import Guru from "views/Directory/Guru.js";
import Sekolah from 'views/Directory/Sekolah';
import SignIn from "views/Auth/SignIn.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  GlobeIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

import { Icon } from '@chakra-ui/react'
import { School } from '@material-ui/icons';

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/admin",
  },
  // {
  //   name: "DIREKTORI",
  //   category: "direktori",
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/direktori/daftar-guru",
  //       name: "Guru",
  //       icon: <PersonIcon color='inherit' />,
  //       component: Guru,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/direktori/daftar-sekolah",
  //       name: "Sekolah",
  //       icon: <Icon as={School} color='inherit' />,
  //       component: Sekolah,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   rtlName: "لوحة القيادة",
  //   icon: <StatsIcon color='inherit' />,
  //   component: Tables,
  //   layout: "/admin",
  // },
  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   rtlName: "صفحات",
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       rtlName: "لوحة القيادة",
  //       icon: <PersonIcon color='inherit' />,
  //       secondaryNavbar: true,
  //       component: Profile,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/signin",
  //       name: "Sign In",
  //       rtlName: "لوحة القيادة",
  //       icon: <DocumentIcon color='inherit' />,
  //       component: SignIn,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/signup",
  //       name: "Sign Up",
  //       rtlName: "لوحة القيادة",
  //       icon: <RocketIcon color='inherit' />,
  //       component: SignUp,
  //       layout: "/auth",
  //     },
  //   ],
  // },
];
export default dashRoutes;
