import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Icon,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
// Assets
import signInImage from "assets/img/signInImageklk3.png";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';
import Cookies from 'js-cookie';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const jar = new CookieJar();
  const client = wrapper(axios.create({ jar }));

  async function handleLogin() {
    // await client.post("http://maiharta.ddns.net:3099/api/auth/signin", {
    //   "username": username,
    //   "password": password
    // }).then((res) => {
    //   console.log(res.jar.toJSON())
    //   // client.get("http://maiharta.ddns.net:3099/api/test", { withCredentials: true })
    //   // .then((resp)=>{
    //   //   console.log(resp)
    //   // })
    // })
    // var cookies = await cookiejar.getCookies("https://httpbin.org/cookies/set/session/userid");
    // console.log(cookies)
    axios.get("https://httpbin.org/cookies/set/session/userid", { withCredentials: true})
    .then((res)=>{
      console.log(res)
    })
    // client.get("https://httpbin.org/cookies/set/session/userid",
    //   {
    //     withCredentials: true
    //   }).then(({ config }) => {
    //   console.log(config.jar.toJSON());
    // });
  }

  return (
    <Flex position='relative' mb='40px'>
      <Flex
        minH={{ sm: "940px", md: "945px" }}
        h={{ sm: "initial", md: "55vh", lg: "65vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ md: "0px" }}>
        <Flex
          w='100%'
          h='100%'
          alignItems='center'
          justifyContent='center'
          mb='60px'
          mt={{ base: "50px", md: "20px" }}>
          <Flex
            zIndex='2'
            direction='column'
            w='445px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: "100px" }}
            m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Username
              </FormLabel>
              <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='text'
                placeholder='Username/Email anda'
                mb='24px'
                size='lg'
                onChange={(e) => {
                  setUsername(e.target.value)
                }}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Password
              </FormLabel>
              <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='password'
                placeholder='Password anda'
                mb='24px'
                size='lg'
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
              <FormControl display='flex' alignItems='center' mb='24px'>
                <Switch id='remember-login' colorScheme='blue' me='10px' />
                <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal'>
                  Ingat saya
                </FormLabel>
              </FormControl>
              <Button
                fontSize='10px'
                variant='dark'
                fontWeight='bold'
                w='100%'
                h='45'
                mb='24px'
                onClick={() => {handleLogin()}}
                >
                MASUK
              </Button>
            </FormControl>
          </Flex>
        </Flex>
        <Box
          overflowX='hidden'
          h='100%'
          w='100%'
          left='0px'
          position='absolute'
          bgImage={signInImage}>
          <Box
            w='100%'
            h='100%'
            bgSize='cover'
            bg='blue.500'
            opacity='0.8'></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
