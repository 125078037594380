import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import PrivateRoute from 'components/Auth/PrivateRoute';
import PublicRoute from 'components/Auth/PublicRoute';
import { ChakraProvider } from "@chakra-ui/react";
// Custom Chakra theme
import theme from "theme/theme.js";
class App extends Component {

    render() {
      return (
        <ChakraProvider theme={theme} resetCss={false} position="relative">
            <BrowserRouter>
            <Switch>
                {/* <PublicRoute restricted={true} path={`/auth`} component={AuthLayout} />
                <PrivateRoute path={`/admin`} component={AdminLayout} /> */}
                <Route path={`/auth`} component={AuthLayout} />
                <Route path={`/admin`} component={AdminLayout} />
                <Redirect from={`/`} to="/admin/dashboard" />
            </Switch>
            </BrowserRouter>
        </ChakraProvider>
      );
    }
  }
  
  export default App;