// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Progress,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  HStack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "variables/api";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
// Variables
import {
  barChartData,
  barChartOptions,
  lineChartData,
  lineChartOptions,
} from "variables/charts";
import { pageVisits, socialTraffic } from "variables/general";

export default function Dashboard() {
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");
  const bgIcons = useColorModeValue("white", "blue.500");
  const bgActive = useColorModeValue("blue.500", "navy.700");
  const bgIconsHover = useColorModeValue("blue.800", "blue.800");

  const { colorMode } = useColorMode();

  const [guru, setGuru] = useState(
    {
      data: [],
      meta: {}
    }
  )
  const [sekolah, setSekolah] = useState(
    {
      data: [],
      meta: {}
    }
  )
  const [count, setCount] = useState(
    {
      available: [],
      required: []
    }
  )
  const [wilayah, setWilayah] = useState("DAWAN")
  const [jabatan, setJabatan] = useState("")

  async function fetchData(wilayah, jabatan) {
    try {
      setGuru(
        {
          data: [],
          meta: {}
        }
      )
      setSekolah(
        {
          data: [],
          meta: {}
        }
      )
      setCount(
        {
          available: [],
          required: []
        }
      )
      let guruQuery = ""
      if (jabatan !== undefined) {
        guruQuery = "guru-jabatan?page=1&limit=5&wilayah="+ wilayah +"&sort=tahunPensiun&order=DESC" + "&jabatan=" + jabatan
      } else {
        guruQuery = "guru?page=1&limit=5&wilayah="+ wilayah +"&sort=tahunPensiun&order=DESC"
      }
      let getGuruAll = axios.get(apiUrl + guruQuery);
      let getSekolah = axios.get(apiUrl + "sekolah?page=1&limit=5&wilayah=" + wilayah + "&sort=id&order=ASC");
      let getCountGuru = axios.get(apiUrl + "guru/count?wilayah=" + wilayah)
      let getCountKebutuhan = axios.get(apiUrl + "sekolah/count?wilayah=" + wilayah)
      axios.all([getGuruAll, getSekolah, getCountGuru, getCountKebutuhan]).then(axios.spread((guru, sekolah, countGuru, countKeb) => {
        setGuru(guru.data)
        setSekolah(sekolah.data)
        let avail = [
          {
            guruKelas: 0,
            guruAgama: 0,
            guruPjok: 0,
            muatanLokal: 0,
            penjaga: 0,
            tu: 0,
          }
        ]
        countGuru.data.map((c) => {
          switch (c.jabatan) {
            case "Guru Kelas":
              avail[0].guruKelas = parseInt(c.jumlah)
              break
            case "Guru Agama":
              avail[0].guruAgama = parseInt(c.jumlah)
              break
            case "Guru PJOK":
              avail[0].guruPjok = parseInt(c.jumlah)
              break
            case "Guru Muatan Lokal":
              avail[0].muatanLokal = parseInt(c.jumlah)
              break
            case "Tata Usaha":
              avail[0].tu = parseInt(c.jumlah)
              break
            case "PENJAGA SEKOLAH":
              avail[0].penjaga = parseInt(c.jumlah)
              break
            case "Penjaga Sekolah":
              avail[0].penjaga = parseInt(c.jumlah)
              break
          }
        })
        setCount({
          available: avail,
          required: countKeb.data
        })
      }));
    } catch (error) {
      console.error(error);
    }
  }

  function textSpawner(jabatan) {
    if ( count.available.length !== 0 && count.required.length !== 0) {
      switch (jabatan) {
        case "Guru Kelas":
          let sub = count.available[0].guruKelas - parseInt(count.required[0].totKebGuruKelas)
          if ( sub < 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='red.500' fontWeight='bold'>
                  {sub}{" "}
                </Text>
                Kekurangan Tenaga
              </Text>
            )
            break
          } else if ( sub > 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='green.400' fontWeight='bold'>
                  +{sub}{" "}
                </Text>
                Kelebihan Tenaga
              </Text>
            )
            break
          }
        case "Guru Agama":
          let sub1 = count.available[0].guruAgama - parseInt(count.required[0].totKebGuruAgama)
          if ( sub1 < 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='red.500' fontWeight='bold'>
                  {sub1}{" "}
                </Text>
                Kekurangan Tenaga
              </Text>
            )
            break
          } else if ( sub1 > 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='green.400' fontWeight='bold'>
                  +{sub1}{" "}
                </Text>
                Kelebihan Tenaga
              </Text>
            )
            break
          }
        case "Guru PJOK":
          let sub2 = count.available[0].guruPjok - parseInt(count.required[0].totKebGuruPjok)
          if ( sub2 < 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='red.500' fontWeight='bold'>
                  {sub2}{" "}
                </Text>
                Kekurangan Tenaga
              </Text>
            )
          } else if ( sub2 > 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='green.400' fontWeight='bold'>
                  +{sub2}{" "}
                </Text>
                Kelebihan Tenaga
              </Text>
            )
          }
          break
        case "Guru Muatan Lokal":
          let sub3 = count.available[0].muatanLokal - parseInt(count.required[0].totKebMuatanLokal)
          if ( sub3 < 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='red.500' fontWeight='bold'>
                  {sub3}{" "}
                </Text>
                Kekurangan Tenaga
              </Text>
            )
          } else if ( sub3 > 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='green.400' fontWeight='bold'>
                  +{sub3}{" "}
                </Text>
                Kelebihan Tenaga
              </Text>
            )
          }
          break
        case "Tata Usaha":
          let sub4 = count.available[0].tu - parseInt(count.required[0].totKebTu)
          if ( sub4 < 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='red.500' fontWeight='bold'>
                  {sub4}{" "}
                </Text>
                Kekurangan Tenaga
              </Text>
            )
          } else if ( sub4 > 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='green.400' fontWeight='bold'>
                  +{sub4}{" "}
                </Text>
                Kelebihan Tenaga
              </Text>
            )
          }
          break
        case "Penjaga Sekolah":
          let sub5 = count.available[0].penjaga - parseInt(count.required[0].totKebPenjaga)
          if ( sub5 < 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='red.500' fontWeight='bold'>
                  {sub5}{" "}
                </Text>
                Kekurangan Tenaga
              </Text>
            )
          } else if ( sub5 > 0 ) {
            return (
              <Text color='gray.400' fontSize='sm'>
                <Text as='span' color='green.400' fontWeight='bold'>
                  +{sub5}{" "}
                </Text>
                Kelebihan Tenaga
              </Text>
            )
          }
          break
      }
    }
  }

  function titleSpawner(text) {
    if (text === "") {
      return "Daftar Guru / TU"
    } else {
      if (text.includes("Kontrak")) {
        let tate = text.split(" ")
        return "Daftar " + tate[0] + " " + tate[2]
      } else {
        return "Daftar " + text
      }
    }
  }

  // useEffect(()=>{
  //   fetchData('DAWAN')
  // }, [])

  return (
    <Flex flexDirection='column' pt={{ base: "100px", md: "75px" }}>
      {/* Wilayah */}
      {/* <HStack spacing='15px' justify='center' mb='22px'>
        <Flex
          justify='center'
          align='center'
          w='125px'
          h='75px'
          borderRadius='8px'
          border={useColorModeValue("1px solid", "0px")}
          borderColor='gray.200'
          cursor='pointer'
          transition='all .25s ease'
          fontWeight={wilayah === "KLUNGKUNG" ? "bold" : "normal"}
          bg={wilayah === "KLUNGKUNG" ? bgIcons : bgActive}
          _hover={wilayah === "KLUNGKUNG" ? { bg: bgIcons, textColor:"white" } : { bg: bgIconsHover, textColor:"black" } }
          onClick={() => {
            setWilayah("KLUNGKUNG")
            setGuru(
              {
                data: [],
                meta: {}
              }
            )
            setSekolah(
              {
                data: [],
                meta: {}
              }
            )
            setCount(
              {
                available: [],
                required: []
              }
            )
            fetchData("KLUNGKUNG")
          }}>
          <Text color={wilayah === "KLUNGKUNG" ? textColor : "white"}>
            Klungkung
          </Text>
        </Flex>
        <Flex
          justify='center'
          align='center'
          w='125px'
          h='75px'
          borderRadius='8px'
          border={useColorModeValue("1px solid", "0px")}
          borderColor='gray.200'
          cursor='pointer'
          transition='all .25s ease'
          fontWeight={wilayah === "DAWAN" ? "bold" : "normal"}
          bg={wilayah === "DAWAN" ? bgIcons : bgActive}
          _hover={wilayah === "DAWAN" ? { bg: bgIcons, textColor:"white" } : { bg: bgIconsHover, textColor:"black" } }
          onClick={() => {
            setWilayah("DAWAN")
            setGuru(
              {
                data: [],
                meta: {}
              }
            )
            setSekolah(
              {
                data: [],
                meta: {}
              }
            )
            setCount(
              {
                available: [],
                required: []
              }
            )
            fetchData("DAWAN")
          }}>
          <Text color={wilayah === "DAWAN" ? textColor : "white"}>
            Dawan
          </Text>
        </Flex>
        <Flex
          justify='center'
          align='center'
          w='125px'
          h='75px'
          borderRadius='8px'
          border={useColorModeValue("1px solid", "0px")}
          borderColor='gray.200'
          cursor='pointer'
          transition='all .25s ease'
          fontWeight={wilayah === "BANJARANGKAN" ? "bold" : "normal"}
          bg={wilayah === "BANJARANGKAN" ? bgIcons : bgActive}
          _hover={wilayah === "BANJARANGKAN" ? { bg: bgIcons, textColor:"white" } : { bg: bgIconsHover, textColor:"black" } }
          onClick={() => {
            setWilayah("BANJARANGKAN")
            setGuru(
              {
                data: [],
                meta: {}
              }
            )
            setSekolah(
              {
                data: [],
                meta: {}
              }
            )
            setCount(
              {
                available: [],
                required: []
              }
            )
            fetchData("BANJARANGKAN")
          }}>
          <Text color={wilayah === "BANJARANGKAN" ? textColor : "white"}>
            Banjarangkan
          </Text>
        </Flex>
        <Flex
          justify='center'
          align='center'
          w='125px'
          h='75px'
          borderRadius='8px'
          border={useColorModeValue("1px solid", "0px")}
          borderColor='gray.200'
          cursor='pointer'
          transition='all .25s ease'
          fontWeight={wilayah === "NUSA PENIDA" ? "bold" : "normal"}
          bg={wilayah === "NUSA PENIDA" ? bgIcons : bgActive}
          _hover={wilayah === "NUSA PENIDA" ? { bg: bgIcons, textColor:"white" } : { bg: bgIconsHover, textColor:"black" } }
          onClick={() => {
            setWilayah("NUSA PENIDA")
            setGuru(
              {
                data: [],
                meta: {}
              }
            )
            setSekolah(
              {
                data: [],
                meta: {}
              }
            )
            setCount(
              {
                available: [],
                required: []
              }
            )
            fetchData("NUSA PENIDA")
          }}>
          <Text color={wilayah === "NUSA PENIDA" ? textColor : "white"}>
            Nusa Penida
          </Text>
        </Flex>
      </HStack> */}
      {/* Jumlah Guru */}
      <SimpleGrid columns={{ sm: 2, md: 3, xl: 6 }} spacing='24px' mb='20px'>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru Agama
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].guruKelas : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebGuruKelas) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Guru Kelas" ? bgIconsHover : bgActive}
                _hover={jabatan === "Guru Kelas" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Guru Kelas")
                  fetchData(wilayah, "Guru Kelas")
                }}
              >
                <WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Guru Kelas")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru PKN
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].guruAgama : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebGuruAgama) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Guru Agama" ? bgIconsHover : bgActive}
                _hover={jabatan === "Guru Agama" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Guru Agama")
                  fetchData(wilayah, "Guru Agama")
                }}
                >
                <GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Guru Agama")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru Bahasa Indonesia
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].guruPjok : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebGuruPjok) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Guru PJOK" ? bgIconsHover : bgActive}
                _hover={jabatan === "Guru PJOK" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Guru PJOK")
                  fetchData(wilayah, "Guru PJOK")
                }}
                >
                <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Guru PJOK")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru Bahasa Inggris
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].muatanLokal : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebMuatanLokal) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Guru Muatan Lokal" ? bgIconsHover : bgActive}
                _hover={jabatan === "Guru Muatan Lokal" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Guru Muatan Lokal")
                  fetchData(wilayah, "Guru Muatan Lokal")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Guru Muatan Lokal")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru Matematika
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].penjaga : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebPenjaga) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Penjaga Sekolah" ? bgIconsHover : bgActive}
                _hover={jabatan === "Penjaga Sekolah" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Penjaga Sekolah")
                  fetchData(wilayah, "Penjaga Sekolah")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Penjaga Sekolah")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru IPA
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].tu : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebTu) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Tata Usaha" ? bgIconsHover : bgActive}
                _hover={jabatan === "Tata Usaha" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Tata Usaha")
                  fetchData(wilayah, "Tata Usaha")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Tata Usaha")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru IPS
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].tu : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebTu) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Tata Usaha" ? bgIconsHover : bgActive}
                _hover={jabatan === "Tata Usaha" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Tata Usaha")
                  fetchData(wilayah, "Tata Usaha")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Tata Usaha")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Seni Budaya
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].tu : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebTu) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Tata Usaha" ? bgIconsHover : bgActive}
                _hover={jabatan === "Tata Usaha" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Tata Usaha")
                  fetchData(wilayah, "Tata Usaha")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Tata Usaha")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru Penjaskes
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].tu : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebTu) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Tata Usaha" ? bgIconsHover : bgActive}
                _hover={jabatan === "Tata Usaha" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Tata Usaha")
                  fetchData(wilayah, "Tata Usaha")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Tata Usaha")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru Prakarya
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].tu : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebTu) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Tata Usaha" ? bgIconsHover : bgActive}
                _hover={jabatan === "Tata Usaha" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Tata Usaha")
                  fetchData(wilayah, "Tata Usaha")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Tata Usaha")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru Bahasa Bali
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].tu : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebTu) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Tata Usaha" ? bgIconsHover : bgActive}
                _hover={jabatan === "Tata Usaha" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Tata Usaha")
                  fetchData(wilayah, "Tata Usaha")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Tata Usaha")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru TIKOM
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].tu : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebTu) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Tata Usaha" ? bgIconsHover : bgActive}
                _hover={jabatan === "Tata Usaha" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Tata Usaha")
                  fetchData(wilayah, "Tata Usaha")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Tata Usaha")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah Guru BK
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].tu : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebTu) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Tata Usaha" ? bgIconsHover : bgActive}
                _hover={jabatan === "Tata Usaha" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Tata Usaha")
                  fetchData(wilayah, "Tata Usaha")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Tata Usaha")}
          </Flex>
        </Card>
        <Card minH='100px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Jumlah TU
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {count.available.length !== 0 ? count.available[0].tu : '0'} / {count.required.length !== 0 ? parseInt(count.required[0].totKebTu) : '0'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={jabatan === "Tata Usaha" ? bgIconsHover : bgActive}
                _hover={jabatan === "Tata Usaha" ? { bg: bgIconsHover} : { bg: bgIconsHover } }
                onClick={() => {
                  setJabatan("Tata Usaha")
                  fetchData(wilayah, "Tata Usaha")
                }}
                >
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {textSpawner("Tata Usaha")}
          </Flex>
        </Card>
      </SimpleGrid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
        templateRows={{ lg: "repeat(2, auto)" }}
        gap='20px'>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                {titleSpawner(jabatan)}
              </Text>
              <NavLink 
                to={{
                  pathname: '/admin/direktori/daftar-guru',
                  state: {
                    wilayah: wilayah,
                    jabatan: jabatan
                  }
                }}
              >
                <Button variant='primary' maxH='30px'>
                  LIHAT SEMUA
                </Button>
              </NavLink>
            </Flex>
            <Box overflow={{ sm: "scroll", lg: "hidden" }}>
              <Table>
                <Thead>
                  <Tr bg={tableRowColor}>
                    <Th color='gray.400' borderColor={borderColor}>
                      Nama
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Sekolah
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Kontrak
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Pensiun
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {guru.data.length !== 0 ? guru.data.map((el, index, arr) => {
                    return (
                      <Tr key={index}>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          fontWeight='bold'
                          borderColor={borderColor}
                          border={index === arr.length - 1 ? "none" : null}>
                          {el.nama}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.sekolah.nama}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.keterangan}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el.tahunPensiun}
                        </Td>
                      </Tr>
                    );
                  }) : ""}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Daftar Sekolah
              </Text>
              <NavLink to='/admin/direktori/daftar-sekolah'>
                <Button variant='primary' maxH='30px'>
                  LIHAT SEMUA
                </Button>
              </NavLink>
            </Flex>
          </Flex>
          <Box overflow={{ sm: "scroll", lg: "hidden" }}>
            <Table>
              <Thead>
                <Tr bg={tableRowColor}>
                  <Th color='gray.400' borderColor={borderColor}>
                    Nama
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Jumlah Siswa
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Kebutuhan Tenaga
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {sekolah.data.length !== 0 ? sekolah.data.map((el, index, arr) => {
                  return (
                    <Tr key={index}>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        fontWeight='bold'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.nama}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.jumlahSiswa}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        <Flex align='center'>
                          <Text
                            color={textTableColor}
                            fontWeight='bold'
                            fontSize='sm'
                            me='12px'>{`${el.kebutuhan}`}</Text>
                          {/* <Progress
                            size='xs'
                            colorScheme={el.color}
                            value={el.percentage}
                            minW='120px'
                          /> */}
                        </Flex>
                      </Td>
                    </Tr>
                  );
                }) : ""}
              </Tbody>
            </Table>
          </Box>
        </Card>
      </Grid>
    </Flex>
  );
}
